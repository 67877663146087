import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '..';

interface SocketState {
	meetupsLiveStatus: ISocketLiveStatus;
	lessonsLiveStatus: ISocketLessonZoomMeeting;
	lessonMessageNotifications: ISocketLessonZoomMeetingStatusMessagePayload[];
	s3VideoStatusStatus: IS3VideoStatus;
}

const initialState: SocketState = {
	meetupsLiveStatus: {},
	lessonsLiveStatus: {},
	/**
	 * used to trigger the lesson message notification
	 * should be triggered by socket event
	 * support multiple notifications
	 * isLive in the message could be true or false, therefore, it could be used to open or close notification
	 */
	lessonMessageNotifications: [],
	s3VideoStatusStatus: {}
};

const socketSlice = createSlice({
	name: 'socket',
	initialState,
	reducers: {
		updateMeetupLiveStatus: (
			state,
			action: PayloadAction<ISocketZoomMeetingMeetupStatusMessage>
		) => {
			if (action.payload?.id) {
				state.meetupsLiveStatus[action.payload.id] = action.payload.isLive;
			}
		},
		updateLessonLiveStatus: (
			state,
			action: PayloadAction<ISocketLessonZoomMeetingStatusMessagePayload>
		) => {
			if (action.payload?.id) {
				state.lessonsLiveStatus[action.payload.id] = action.payload;
				state.lessonMessageNotifications = [action.payload];
			}
		},
		initializeLessonMessageNotifications: (
			state,
			action: PayloadAction<ISocketLessonZoomMeetingStatusMessagePayload[]>
		) => {
			// normally, it only includes live lesson messages
			state.lessonMessageNotifications = action.payload;
		},
		updateS3VideoStatus: (state, action: PayloadAction<IS3VideoStatusMessagePayload>) => {
			if (action.payload?.id) {
				state.s3VideoStatusStatus[action.payload.id] = action.payload.status;
			}
		}
	}
});

export const {
	updateMeetupLiveStatus,
	updateLessonLiveStatus,
	initializeLessonMessageNotifications,
	updateS3VideoStatus
} = socketSlice.actions;
export const selectMeetupsLiveStatus = (state: RootState) => state.socket.meetupsLiveStatus;
export const selectLessonsLiveStatus = (state: RootState) => state.socket.lessonsLiveStatus;
export const selectLessonMessageNotifications = (state: RootState) =>
	state.socket.lessonMessageNotifications;
export const selectS3VideoStatusStatus = (state: RootState) => state.socket.s3VideoStatusStatus;

export default socketSlice.reducer;
